import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import useAuth from '../components/use-auth';
import Layout from "../components/layout"
import SEO from "../components/seo"
import UsersList from '../components/users-list'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(4),
  },
}));

const UsersPage = ({
  path
}) => {
  const { t } = useTranslation('usersList');
  const auth = useAuth();
  const classes = useStyles();
  return (
    <Layout auth={auth} path={path}>
      <SEO title="Users" />
      { auth && (
        <div>
          <Button 
              className={classes.button}
              component={Link}
              to="/users/add" 
              fullWidth  
              variant="contained"
              color="primary"
            >
                {t('addUser')}
            </Button>
          <UsersList />
        </div>
      )}
    </Layout>
  )
}

export default UsersPage;
