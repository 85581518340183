import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import AdminIcon from '@material-ui/icons/Security';
import FaceIcon from '@material-ui/icons/Face';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import TransitionAlert from './transition-alert';
import { useSearch } from './search-context';
import { EP, callApi } from '../api';
import Loading from './loading';
import { USER_ROLES } from '../constants';
import DialogButton from './dialog-button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  noItems: {
    padding:  theme.spacing(8, 0),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    display: 'block',
  }
}));

const sortUsersByGivenName = (a, b) => {
  if (a.givenName < b.givenName)
    return -1;
  if (a.givenName > b.givenName)
    return 1;
  return 0;
}

const sortUsersByName = (a, b) => {
  if (a.name < b.name)
    return -1;
  if (a.name > b.name)
    return 1;
  return sortUsersByGivenName(a, b);
}

const rolesOrder = [USER_ROLES.SYSTEM_ADMIN, USER_ROLES.TENANT_ADMIN , USER_ROLES.TENANT_USER];
const sortUsersByRoleAndName = (a, b) => {
  if (rolesOrder.indexOf(a.role) < rolesOrder.indexOf(b.role))
    return -1;
    if (rolesOrder.indexOf(a.role) > rolesOrder.indexOf(b.role))
    return 1;
  return sortUsersByName(a, b);
}

const getUsersFiltered = (users, searchTerm) => {
  const re = new RegExp(searchTerm.toLowerCase());
  return users
    .filter(u => re.test(JSON.stringify([
      u.username,
      u.givenName,
      u.name
    ]).toLowerCase()))
    .sort(sortUsersByRoleAndName);
}

const UsersList = () => {
  const { t } = useTranslation('usersList');
  const classes = useStyles();
  const initialDeleteUser = { isDeleting: false, error: null, isSuccess: false };
  const [deleteUser, setDeleteUser] = useState(initialDeleteUser);
  const [searchTerm] = useSearch();
  const { users, isLoading, isError, setUsers } = useFetchUsers();
  const usersFiltered = getUsersFiltered(users, searchTerm);

  const handleDelete = async (username) => {
    try {
      setDeleteUser(initialDeleteUser);
      await callApi(EP.users.delete, { username });
      setUsers(users.filter(user => user.username !== username));
      setDeleteUser({...initialDeleteUser, isSuccess: true });
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message)
        || t('errorDelete'); 
      setDeleteUser({ ...initialDeleteUser, error: message });
    }       
  }

  const handleCloseDeleteAlert = () => setDeleteUser(initialDeleteUser);

  if (isLoading || deleteUser.isDeleting) return <Loading />
  if (isError) return (
    <TransitionAlert severity="error" isOpen>
      {t('errorFetch')}
    </TransitionAlert>
  );
  return (
    <div>
      { deleteUser.isSuccess && (
        <TransitionAlert severity="success" isOpen className={classes.alert} onClose={handleCloseDeleteAlert}>
          {t('deleteSuccess')}
        </TransitionAlert>
      )}
      { deleteUser.error && (
        <TransitionAlert severity="error" isOpen className={classes.alert} onClose={handleCloseDeleteAlert}>
          { deleteUser.error }
        </TransitionAlert>
      )}
      { !usersFiltered.length && <Typography className={classes.noItems}>{t('noUsers')}</Typography> }
      <List className={classes.root}>
      { usersFiltered.map(user => (
        <React.Fragment key={user.username}>
          <ListItem alignItems="flex-start" button component={Link} to={`/users/edit?username=${user.username}`}>
            <ListItemAvatar>
              <Avatar>
                { user.role === USER_ROLES.TENANT_OWNER && <StarBorderIcon /> }
                { user.role === USER_ROLES.TENANT_ADMIN && <AdminIcon /> }
                { user.role === USER_ROLES.TENANT_USER && <FaceIcon /> }
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${user.name}, ${user.givenName}`}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    { user.role === USER_ROLES.SYSTEM_ADMIN && t('roleSysAdmin') }
                    { user.role === USER_ROLES.TENANT_OWNER && t('roleOwner') }
                    { user.role === USER_ROLES.TENANT_ADMIN && t('roleAdmin') }
                    { user.role === USER_ROLES.TENANT_USER && t('roleEmployee') }
                  </Typography>
                  {` · @${user.username}`}
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <DialogButton
                ariaLabel="delete"
                edge="end"
                type="icon"
                title={t('deleteUser', { username: user.username })}
                description={t('confirmDelete', { username: user.username, firstName: user.givenName, lastName: user.name })}
                onConfirm={() => handleDelete(user.username)}
              >
                 <DeleteIcon />
              </DialogButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
    </List>
    </div>
  );
}

const reshapeUser = user => {
  const transformedAttrs = user.Attributes.reduce((acc, attr) => ({ ...acc, [attr.Name]: attr.Value }), {});
  return {
    givenName: transformedAttrs.given_name,
    name: transformedAttrs.name,
    email: transformedAttrs.email,
    username: user.Username,
    role: transformedAttrs['custom:role'],
    stores: transformedAttrs['custom:stores'],
    tenantId: transformedAttrs['custom:tenantid'],
  }
}

const useFetchUsers = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const { Users: users } = await callApi(EP.users.list);
        setIsLoading(false);
        setUsers(users.map(reshapeUser));
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        setIsError(true);
      }
    }
    fetchData();
  }, []);
  
  return { users, isLoading, isError, setUsers };
}

export default UsersList;